import React from 'react'

const Navbar = ({ className, children }) => {
  return (
    <nav className={className ? `navbar ${className}` : 'navbar'}>
      {children}
    </nav>
  )
}

export default Navbar

export const NavbarBrand = ({ className, href, children }) => {
  return (
    <a
      className={className ? `navbar-brand ${className}` : 'navbar-brand'}
      href={href}
    >
      {children}
    </a>
  )
}

export const NavbarToggler = () => {
  return (
    <button
      className='navbar-toggler'
      type='button'
      data-toggle='collapse'
      data-target='#navbarContent'
      aria-controls='navbarContent'
      aria-expanded='false'
      aria-label='Toggle navigation'
    >
      <svg xmlns='http://www.w3.org/2000/svg' width='30' height='30'>
        <path
          stroke='rgba(0, 0, 0, 0.5)'
          strokeLinecap='round'
          strokeMiterlimit='10'
          strokeWidth='2'
          d='M4 7h22M4 15h22M4 23h22'
        />
      </svg>
    </button>
  )
}

export const NavbarCollapse = ({ className, id, children }) => {
  return (
    <div
      className={
        className
          ? `collapse navbar-collapse ${className}`
          : 'collapse navbar-collapse'
      }
      id={id}
    >
      {children}
    </div>
  )
}

export const NavbarNav = ({ className, children }) => {
  return (
    <ul className={className ? `navbar-nav ${className}` : 'navbar-nav'}>
      {children}
    </ul>
  )
}

export const NavbarItem = ({ className, children }) => {
  return (
    <li className={className ? `navbar-item ${className}` : 'navbar-item'}>
      {children}
    </li>
  )
}

export const NavbarLink = ({ className, href, onClick, children }) => {
  return (
    <a
      className={className ? `nav-link ${className}` : 'nav-link'}
      href={href}
      onClick={onClick}
    >
      {children}
    </a>
  )
}
