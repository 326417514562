import React from 'react'
import Card, { CardImgTop, CardColumns } from './card'

import residential_1 from '../static/photos/residential-1.jpg'
import residential_2 from '../static/photos/residential-2.jpg'
import residential_3 from '../static/photos/residential-3.jpg'
import residential_4 from '../static/photos/residential-4.jpg'
import residential_5 from '../static/photos/residential-5.jpg'
import residential_6 from '../static/photos/residential-6.jpg'
import residential_7 from '../static/photos/residential-7.jpg'
import residential_8 from '../static/photos/residential-8.jpg'
import residential_9 from '../static/photos/residential-9.jpg'

const ResidentialGallery = () => {
  return (
    <CardColumns>
      {/* COLUMN ONE */}
      <Card>
        <CardImgTop src={residential_1} />
      </Card>
      <Card>
        <CardImgTop src={residential_2} />
      </Card>
      <Card>
        <CardImgTop src={residential_3} />
      </Card>
      {/* END COLUMN ONE */}

      {/* COLUMN TWO */}
      <Card>
        <CardImgTop src={residential_4} />
      </Card>
      <Card>
        <CardImgTop src={residential_5} />
      </Card>
      <Card>
        <CardImgTop src={residential_6} />
      </Card>
      {/* END COLUMN TWO */}

      {/* COLUMN THREE */}
      <Card>
        <CardImgTop src={residential_7} />
      </Card>
      <Card>
        <CardImgTop src={residential_8} />
      </Card>
      <Card>
        <CardImgTop src={residential_9} />
      </Card>
      {/* END COLUMN THREE */}
    </CardColumns>
  )
}

export default ResidentialGallery
