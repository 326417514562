import React from 'react'
import { Helmet } from 'react-helmet'
import './App.css'
import Routes from './routes'
import styles from './styles.module.css'
import favicon from '../public/favicon.ico'

function App () {
  return (
    <div className='App'>
      <Helmet>
        <link rel='icon' href={favicon} />
      </Helmet>
      <div className={styles.fixedAdjust} />
      <Routes />
    </div>
  )
}

export default App
