import React from 'react'

const Jumbotron = ({ className, children }) => {
  return (
    <div className={className ? `jumbotron ${className}` : 'jumbotron'}>
      {children}
    </div>
  )
}

export default Jumbotron

export const JumbotronHeader = ({ className, children }) => {
  return (
    <h2 className={className ? `display-4 ${className}` : 'display-4'}>
      {children}
    </h2>
  )
}

export const JumbotronLead = ({ className, children }) => {
  return <p className={className ? `lead ${className}` : 'lead'}>{children}</p>
}

export const JumbotronDivider = ({ className, children }) => {
  return <hr className={className ? `my-4 ${className}` : 'my-4'} />
}
