import React from 'react'

const styleMain = {
  boxSizing: 'border-box',
  width: '100%',
  padding: '1rem'
}

const Main = ({ className, children }) => {
  return (
    <main style={styleMain} className={className ? className : null}>
      {children}
    </main>
  )
}

export default Main
