import { Switch, Route } from 'react-router-dom'
import React from 'react'
import Home from '../views/home'

const Routes = () => {
  return (
    <Switch>
      <Route exact path='/'>
        <Home />
      </Route>
    </Switch>
  )
}

export default Routes
