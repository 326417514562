import React from 'react'
import $ from 'jquery'
import Navbar, {
  NavbarBrand,
  NavbarToggler,
  NavbarCollapse,
  NavbarNav,
  NavbarItem,
  NavbarLink
} from '../../../components/navbar'
import { BtnOutlineDark } from '../../../components/button'
import styles from '../../../styles.module.css'

const handleServicesScroll = e => {
  e.preventDefault()
  $('.collapse').collapse('hide')
  const element = document.getElementById('services')
  element.scrollIntoView({ behavior: 'smooth' })
}

const handleGalleryScroll = e => {
  e.preventDefault()
  $('.collapse').collapse('hide')
  const element = document.getElementById('gallery')
  element.scrollIntoView({ behavior: 'smooth' })
}

const handleRequestEstimateCollapse = () => {
  $('.collapse').collapse('hide')
}

const NavbarHome = () => {
  return (
    <Navbar className='fixed-top navbar-expand-lg navbar-light bg-light'>
      <NavbarBrand>Duehring Masonry, LLC</NavbarBrand>
      <NavbarToggler />
      <NavbarCollapse id='navbarContent'>
        <NavbarNav className='ml-auto'>
          <NavbarItem className={`${styles.justifyContentCenter} px-2`}>
            <NavbarLink href='#services' onClick={handleServicesScroll}>
              Services
            </NavbarLink>
          </NavbarItem>
          <NavbarItem className={`${styles.justifyContentCenter} px-2`}>
            <NavbarLink href='#gallery' onClick={handleGalleryScroll}>
              Gallery
            </NavbarLink>
          </NavbarItem>
          <NavbarItem>
            <BtnOutlineDark
              className={styles.btnRequestEstimate}
              dataToggle='modal'
              dataTarget='#modalCenter'
              onClick={handleRequestEstimateCollapse}
            >
              Request Estimate
            </BtnOutlineDark>
          </NavbarItem>
        </NavbarNav>
      </NavbarCollapse>
    </Navbar>
  )
}

export default NavbarHome
