import React from 'react'
import Jumbotron, {
  JumbotronHeader,
  JumbotronLead
} from '../../../components/jumbotron'
import Card, {
  CardDeck,
  CardImgTop,
  CardBody,
  CardTitle,
  CardText
} from '../../../components/card'

import image4 from '../../../static/images/image4.jpg'
import image1 from '../../../static/images/image1.jpg'
import image2 from '../../../static/images/image2.jpg'

const Services = () => {
  return (
    <section className='py-3' id='services'>
      <Jumbotron>
        <JumbotronHeader>Services</JumbotronHeader>
        <JumbotronLead>
          Chimney repair | Tuckpointing | Fireplace and mantel stone work | Wall
          restoration | Caulking and patios, stoups and driveways
        </JumbotronLead>
      </Jumbotron>

      <CardDeck>
        <Card>
          <CardImgTop src={image4} />
          <CardBody>
            <CardTitle className='h1'>Residential</CardTitle>
            <CardText>
              Building your next escape requires the highest quality material at
              a cost you desire. Summer in Wisconsin awaits, let's imagine
              together your next great patio for your friend's and family to
              enjoy.
            </CardText>
            <CardText>
              As summer fun flies by, winter is here before you know it. A
              high-rise fireplace maybe what you seek. There's nothing like a
              toast nearby a toasty fire with friends and family.
            </CardText>
          </CardBody>
        </Card>
        <Card>
          <CardImgTop src={image1} />
          <CardBody>
            <CardTitle className='h1'>Commercial</CardTitle>
            <CardText>
              Every venture brings new challenges, my pride in work lays the
              groundwork that'll stand the test of time. As a startup and proud
              owner, I will use all of my resources to keep cost down and
              quality outstanding.
            </CardText>
          </CardBody>
        </Card>
        <Card>
          <CardImgTop src={image2} />
          <CardBody>
            <CardTitle className='h1'>Restoration</CardTitle>
            <CardText>
              When the unexpected happens, don't sweat it because from damaged
              to fixed, Duehring Masonry has serviced it all.
            </CardText>
          </CardBody>
        </Card>
      </CardDeck>
    </section>
  )
}

export default Services
