import React, { useState } from 'react'
import ResidentialGallery from '../../../components/ResidentialGallery'
import CommercialGallery from '../../../components/CommercialGallery'
import Jumbotron, {
  JumbotronHeader,
  JumbotronLead
} from '../../../components/jumbotron'
import Card, { CardImgTop, CardColumns } from '../../../components/card'
import DynamicCard from '../dynamicCard'

/* BEFORE / AFTER IMAGES */

import image1 from '../../../static/images/image1.jpg'
import image11 from '../../../static/images/image11.jpg'

import image21 from '../../../static/images/image21.jpg'
import image4 from '../../../static/images/image4.jpg'

import image15 from '../../../static/images/image15.jpg'
import image14 from '../../../static/images/image14.jpg'

import image2 from '../../../static/images/image2.jpg'
import image3 from '../../../static/images/image3.jpg'

import image26 from '../../../static/images/image26.jpg'
import image27 from '../../../static/images/image27.jpg'

import image9 from '../../../static/images/image9.jpg'
import image7 from '../../../static/images/image7.jpg'

import image16 from '../../../static/images/image16.jpg'
import image17 from '../../../static/images/image17.jpg'

/* END BEFORE / AFTER IMAGES */

/* STATIC IMAGES */

import image10 from '../../../static/images/image10.jpg'
import image13 from '../../../static/images/image13.jpg'

/* END STATIC IMAGES */

const Gallery = () => {
  const [activeGallery, setActiveGallery] = useState("feature")
  return (
    <section className='py-3' id='gallery'>
      <Jumbotron>
        <JumbotronHeader>Gallery</JumbotronHeader>
        <JumbotronLead>
          Historic stone restorations and completed masonry services
        </JumbotronLead>
      </Jumbotron>

      <div class="btn-group w-100 mx-auto pb-4" role="group" aria-label="Basic example">
        <button type="button" class="btn btn-secondary" onClick={() => setActiveGallery("feature")}>Feature</button>
        <button type="button" class="btn btn-secondary" onClick={() => setActiveGallery("residential")}>Residential</button>
        <button type="button" class="btn btn-secondary" onClick={() => setActiveGallery("commercial")}>Commercial</button>
      </div>

      {
        activeGallery === "feature" ? <CardColumns>
          {/* COLUMN ONE */}
          <DynamicCard beforeImg={image11} afterImg={image1} />
          <DynamicCard beforeImg={image2} afterImg={image3} />
          <DynamicCard beforeImg={image9} afterImg={image7} />
          {/* END COLUMN ONE */}

          {/* COLUMN TWO */}
          <DynamicCard beforeImg={image21} afterImg={image4} />
          <DynamicCard beforeImg={image26} afterImg={image27} />
          <DynamicCard beforeImg={image16} afterImg={image17} />
          {/* END COLUMN TWO */}

          {/* COLUMN THREE */}
          <DynamicCard beforeImg={image15} afterImg={image14} />
          <Card>
            <CardImgTop src={image10} />
          </Card>
          <Card>
            <CardImgTop src={image13} />
          </Card>
          {/* END COLUMN THREE */}
        </CardColumns> : activeGallery === "residential" ? <ResidentialGallery /> : activeGallery === "commercial" ? <CommercialGallery /> : null
      }


    </section>
  )
}

export default Gallery
