import React from 'react'

const Modal = ({ children }) => {
  return (
    <div
      className='modal fade'
      id='modalCenter'
      tabIndex='-1'
      role='dialog'
      aria-labelledby='modalCenterTitle'
      aria-hidden='true'
    >
      <div className='modal-dialog modal-dialog-centered' role='document'>
        <div className='modal-content'>
          <div className='modal-header'>
            <h5 className='modal-title' id='modalCenterTitle'>
              Estimate
            </h5>
            <button
              type='button'
              className='close'
              data-dismiss='modal'
              aria-label='Close'
            >
              <span aria-hidden='true'>&times;</span>
            </button>
          </div>
          <div className='modal-body'>{children}</div>
          <div className='modal-footer'>
            <button
              type='button'
              className='btn btn-secondary'
              data-dismiss='modal'
            >
              Close
            </button>
            <button
              className='btn btn-primary'
              type='submit'
              form='form-estimate'
            >
              Submit form
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Modal
