import React, { useState } from 'react'
import Card, { CardImgTop, CardFooter } from '../../../components/card'
import { BtnOutlineDanger, BtnOutlineSuccess } from '../../../components/button'
import styles from '../../../styles.module.css'

const DynamicCard = ({ beforeImg, afterImg }) => {
  const [img, setImg] = useState(beforeImg)

  const handleBefore = () => {
    setImg(beforeImg)
  }

  const handleAfter = () => {
    setImg(afterImg)
  }

  return (
    <Card>
      <CardImgTop src={img} />
      <CardFooter className={styles.cardFooter}>
        <BtnOutlineDanger onClick={handleBefore}>Before</BtnOutlineDanger>
        <BtnOutlineSuccess onClick={handleAfter}>After</BtnOutlineSuccess>
      </CardFooter>
    </Card>
  )
}

export default DynamicCard
