import React from 'react'
import logo from '../../../logo.svg'

const Footer = () => {
  return (
    <footer className='container py-5'>
      <div className='row'>
        <div className='col-12 col-md'>
          <img alt='Duehring Masonry, LLC' src={logo} />
          <small className='d-block mb-3 text-muted'>
            Mequon, WI © 2017-{new Date().getFullYear()}
          </small>
        </div>
        <div className='col-6 col-md'>
          <h5>Residential</h5>
          <ul className='list-unstyled text-small'>
            <li className='text-muted'>Patios</li>
            <li className='text-muted'>Fireplaces</li>
            <li className='text-muted'>Chimneys</li>
            <li className='text-muted'>Garages</li>
            <li className='text-muted'>Steps</li>
            <li className='text-muted'>Stoups</li>
          </ul>
        </div>
        <div className='col-6 col-md'>
          <h5>Restoration</h5>
          <ul className='list-unstyled text-small'>
            <li className='text-muted'>Walls</li>
            <li className='text-muted'>Chimneys</li>
            <li className='text-muted'>Basements</li>
            <li className='text-muted'>Fireplaces</li>
            <li className='text-muted'>Historic</li>
            <li className='text-muted'>Power wash</li>
          </ul>
        </div>
        <div className='col-6 col-md'>
          <h5>Commercial</h5>
          <ul className='list-unstyled text-small'>
            <li className='text-muted'>Doors</li>
            <li className='text-muted'>Structures</li>
            <li className='text-muted'>Foundations</li>
          </ul>
        </div>
        <div className='col-6 col-md'>
          <h5>Contact</h5>
          <ul className='list-unstyled text-small'>
            <li className='text-muted'>
              Email:&nbsp;
              <a
                href='#request-estimate'
                data-toggle='modal'
                data-target='#modalCenter'
              >
                Request Estimate
              </a>
            </li>
            <li className='text-muted'>
              Phone: <a href='tel:262-224-6852'>262-224-6852</a>
            </li>
          </ul>
        </div>
      </div>
    </footer>
  )
}

export default Footer
