import React from 'react'
import logo from '../../../logo.svg'
import image4 from '../../../static/images/image4.jpg'
import image5 from '../../../static/images/image5.jpg'
import image6 from '../../../static/images/image6.jpg'
import styles from '../../../styles.module.css'

const Carousel = () => {
  return (
    <header
      className='carousel slide'
      data-ride='carousel'
      id='carouselExampleIndicators'
    >
      <ol className='carousel-indicators d-none-h'>
        <li data-slide-to='0' data-target='#carouselExampleIndicators'></li>
        <li
          data-slide-to='1'
          data-target='#carouselExampleIndicators'
          className='active'
        ></li>
        <li data-slide-to='2' data-target='#carouselExampleIndicators'></li>
      </ol>
      <div className='carousel-inner'>
        <div className='carousel-item'>
          <img alt='First slide' src={image4} className='d-block w-100' />
        </div>
        <div className='carousel-item active'>
          <img alt='Second slide' src={image5} className='d-block w-100' />
        </div>
        <div className='carousel-item'>
          <img alt='Third slide' src={image6} className='d-block w-100' />
        </div>
        <img alt='Duehring Masonry, LLCs logo.' src={logo} className='cc' />
      </div>
      <div className='carousel-caption d-none-h'>
        <h1 className='lead'>Mequon, WI</h1>
        <p className='lead'>BBB Accredited</p>
        <button
          className='btn d-lg-none d-none-h btn-primary'
          type='button'
          data-target='#modalCenter'
          data-toggle='modal'
        >
          Request Estimate
        </button>
      </div>
      <a
        href='#carouselExampleIndicators'
        className='carousel-control-prev'
        role='button'
        data-slide='prev'
      >
        <span className={styles.carouselPrevIcon}>
          <svg xmlns='http://www.w3.org/2000/svg' fill='#fff' viewBox='0 0 8 8'>
            <path d='M5.25 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z' />
          </svg>
        </span>
        <span className='sr-only'>Previous</span>
      </a>
      <a
        href='#carouselExampleIndicators'
        className='carousel-control-next'
        role='button'
        data-slide='next'
      >
        <span className={styles.carouselNextIcon}>
          <svg xmlns='http://www.w3.org/2000/svg' fill='#fff' viewBox='0 0 8 8'>
            <path d='M2.75 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z' />
          </svg>
        </span>
        <span className='sr-only'>Next</span>
      </a>
    </header>
  )
}

export default Carousel
