import React, { useEffect } from 'react'
import styles from '../../../styles.module.css'

const RequestEstimate = () => {
  useEffect(() => {
    ;(function () {
      window.addEventListener(
        'load',
        function () {
          const forms = document.getElementsByClassName('needs-validation')

          Array.prototype.filter.call(forms, function (form) {
            form.addEventListener(
              'submit',
              function (event) {
                if (form.checkValidity() === false) {
                  event.preventDefault()
                  event.stopPropagation()
                }
                form.classList.add('was-validated')
              },
              false
            )
          })
        },
        false
      )
    })()
  })
  return (
    <form
      action='https://formspree.io/cduehring@yahoo.com'
      className='needs-validation'
      id='form-estimate'
      method='POST'
      noValidate
    >
      <input
        name='_subject'
        type='hidden'
        value='DuehringMasonryLLC.com | Client Estimate Request'
      />

      <div className='form-row'>
        <div className='col-md mb-3'>
          <label htmlFor='validationCustom01'>First name</label>
          <input
            name='first_name'
            type='text'
            className='form-control'
            id='validationCustom01'
            maxLength='30'
            placeholder='First name'
            required
          />
          <div className='valid-feedback'>Looks good!</div>
          <div className='invalid-feedback'>Please provide a valid name.</div>
        </div>
        <div className='col-md mb-3'>
          <label htmlFor='validationCustom02'>Last name</label>
          <input
            name='last_name'
            type='text'
            className='form-control'
            id='validationCustom02'
            maxLength='30'
            placeholder='Last name'
            required
          />
          <div className='valid-feedback'>Looks good!</div>
          <div className='invalid-feedback'>Please provide a valid name.</div>
        </div>
      </div>

      <div className='form-row'>
        <div className='col-md-6 mb-3'>
          <label htmlFor='validationCustom03'>City</label>
          <input
            name='city'
            type='text'
            className='form-control'
            id='validationCustom03'
            maxLength='30'
            placeholder='City'
            required
          />
          <div className='valid-feedback'>Looks good!</div>
          <div className='invalid-feedback'>Please provide a valid city.</div>
        </div>
        <div className='col-md-3 mb-3'>
          <label htmlFor='validationCustom04'>State</label>
          <input
            name='state'
            type='text'
            className='form-control'
            id='validationCustom04'
            maxLength='30'
            placeholder='State'
            required
          />
          <div className='valid-feedback'>Looks good!</div>
          <div className='invalid-feedback'>Please provide a valid state.</div>
        </div>

        <div className='col-md-3 mb-3'>
          <label htmlFor='validationCustom05'>Zip</label>
          <input
            name='zip'
            type='text'
            className='form-control'
            id='validationCustom05'
            maxLength='5'
            placeholder='Zip'
            required
          />
          <small className='text-muted form-text' id='passwordHelpBlock'>
            <span className={styles.fontRed}>*</span>Required format:
            <strong>55555</strong>
          </small>
          <div className='valid-feedback'>Looks good!</div>
          <div className='invalid-feedback'>Please provide a valid zip.</div>
        </div>
      </div>

      <div className='form-row'>
        <div className='col-md mb-3'>
          <label htmlFor='validationCustom07'>Phone</label>
          <input
            name='phone'
            type='text'
            className='form-control'
            id='validationCustom07'
            maxLength='12'
            placeholder='Phone'
            required
            pattern='\d{3}[\-]\d{3}[\-]\d{4}'
          />
          <small className='text-muted form-text' id='passwordHelpBlock'>
            <span className={styles.fontRed}>*</span>Required format:
            <strong>000-000-0000</strong>
          </small>
          <div className='valid-feedback'>Looks good!</div>
          <div className='invalid-feedback'>
            Please provide a valid phone number.
          </div>
        </div>
        <div className='col-md mb-3'>
          <label htmlFor='validationCustom08'>Email</label>
          <input
            name='email'
            type='text'
            className='form-control'
            id='validationCustom08'
            maxLength='30'
            placeholder='Email'
            required
          />
          <div className='valid-feedback'>Looks good!</div>
          <div className='invalid-feedback'>Please provide a valid email.</div>
        </div>
      </div>
      <div className='form-row'>
        <div className='w-100 mb-3'>
          <label htmlFor='validationCustom06'>Project details</label>
          <textarea
            className='form-control'
            id='validationCustom06'
            maxLength='255'
            name='project_details'
            placeholder='Project details'
            required
            rows='3'
          ></textarea>
          <div className='valid-feedback'>Looks good!</div>
          <div className='invalid-feedback'>
            Please provide the project details.
          </div>
        </div>
      </div>
      <input name='_gotcha' type='text' className={styles.displayNone} />
      <input
        name='_next'
        type='hidden'
        value='https://duehringmasonryllc.com'
      />
    </form>
  )
}

export default RequestEstimate
