import React from 'react'

export const BtnPrimary = ({
  dataToggle,
  dataTarget,
  onClick,
  className,
  children
}) => (
  <button
    data-toggle={dataToggle}
    data-target={dataTarget}
    onClick={onClick}
    type='button'
    className={className ? `btn btn-primary ${className}` : 'btn btn-primary'}
  >
    {children}
  </button>
)

export const BtnSecondary = ({
  dataToggle,
  dataTarget,
  onClick,
  className,
  children
}) => (
  <button
    data-toggle={dataToggle}
    data-target={dataTarget}
    onClick={onClick}
    type='button'
    className={
      className ? `btn btn-secondary ${className}` : 'btn btn-secondary'
    }
  >
    {children}
  </button>
)

export const BtnSuccess = ({
  dataToggle,
  dataTarget,
  onClick,
  className,
  children
}) => (
  <button
    data-toggle={dataToggle}
    data-target={dataTarget}
    onClick={onClick}
    type='button'
    className={className ? `btn btn-success ${className}` : 'btn btn-success'}
  >
    {children}
  </button>
)

export const BtnDanger = ({
  dataToggle,
  dataTarget,
  onClick,
  className,
  children
}) => (
  <button
    data-toggle={dataToggle}
    data-target={dataTarget}
    onClick={onClick}
    type='button'
    className={className ? `btn btn-danger ${className}` : 'btn btn-danger'}
  >
    {children}
  </button>
)

export const BtnWarning = ({
  dataToggle,
  dataTarget,
  onClick,
  className,
  children
}) => (
  <button
    data-toggle={dataToggle}
    data-target={dataTarget}
    onClick={onClick}
    type='button'
    className={className ? `btn btn-warning ${className}` : 'btn btn-warning'}
  >
    {children}
  </button>
)

export const BtnInfo = ({
  dataToggle,
  dataTarget,
  onClick,
  className,
  children
}) => (
  <button
    data-toggle={dataToggle}
    data-target={dataTarget}
    onClick={onClick}
    type='button'
    className={className ? `btn btn-info ${className}` : 'btn btn-info'}
  >
    {children}
  </button>
)

export const BtnLight = ({
  dataToggle,
  dataTarget,
  onClick,
  className,
  children
}) => (
  <button
    data-toggle={dataToggle}
    data-target={dataTarget}
    onClick={onClick}
    type='button'
    className={className ? `btn btn-light ${className}` : 'btn btn-light'}
  >
    {children}
  </button>
)

export const BtnDark = ({
  dataToggle,
  dataTarget,
  onClick,
  className,
  children
}) => (
  <button
    data-toggle={dataToggle}
    data-target={dataTarget}
    onClick={onClick}
    type='button'
    className={className ? `btn btn-dark ${className}` : 'btn btn-dark'}
  >
    {children}
  </button>
)

export const BtnLink = ({
  dataToggle,
  dataTarget,
  onClick,
  className,
  children
}) => (
  <button
    data-toggle={dataToggle}
    data-target={dataTarget}
    onClick={onClick}
    type='button'
    className={className ? `btn btn-link ${className}` : 'btn btn-link'}
  >
    {children}
  </button>
)

export const BtnOutlinePrimary = ({
  dataToggle,
  dataTarget,
  onClick,
  className,
  children
}) => (
  <button
    data-toggle={dataToggle}
    data-target={dataTarget}
    onClick={onClick}
    type='button'
    className={
      className
        ? `btn btn-outline-primary ${className}`
        : 'btn btn-outline-primary'
    }
  >
    {children}
  </button>
)

export const BtnOutlineSecondary = ({
  dataToggle,
  dataTarget,
  onClick,
  className,
  children
}) => (
  <button
    data-toggle={dataToggle}
    data-target={dataTarget}
    onClick={onClick}
    type='button'
    className={
      className
        ? `btn btn-outline-secondary ${className}`
        : 'btn btn-outline-secondary'
    }
  >
    {children}
  </button>
)

export const BtnOutlineSuccess = ({
  dataToggle,
  dataTarget,
  onClick,
  className,
  children
}) => (
  <button
    data-toggle={dataToggle}
    data-target={dataTarget}
    onClick={onClick}
    type='button'
    className={
      className
        ? `btn btn-outline-success ${className}`
        : 'btn btn-outline-success'
    }
  >
    {children}
  </button>
)

export const BtnOutlineDanger = ({
  dataToggle,
  dataTarget,
  onClick,
  className,
  children
}) => (
  <button
    data-toggle={dataToggle}
    data-target={dataTarget}
    onClick={onClick}
    type='button'
    className={
      className
        ? `btn btn-outline danger ${className}`
        : 'btn btn-outline-danger'
    }
  >
    {children}
  </button>
)

export const BtnOutlineWarning = ({
  dataToggle,
  dataTarget,
  onClick,
  className,
  children
}) => (
  <button
    data-toggle={dataToggle}
    data-target={dataTarget}
    onClick={onClick}
    type='button'
    className={
      className
        ? `btn btn-outline warning ${className}`
        : 'btn btn-outline-warning'
    }
  >
    {children}
  </button>
)

export const BtnOutlineInfo = ({
  dataToggle,
  dataTarget,
  onClick,
  className,
  children
}) => (
  <button
    data-toggle={dataToggle}
    data-target={dataTarget}
    onClick={onClick}
    type='button'
    className={
      className ? `btn btn-outline-info ${className}` : 'btn btn-outline-info'
    }
  >
    {children}
  </button>
)

export const BtnOutlineLight = ({
  dataToggle,
  dataTarget,
  onClick,
  className,
  children
}) => (
  <button
    data-toggle={dataToggle}
    data-target={dataTarget}
    onClick={onClick}
    type='button'
    className={
      className ? `btn btn-outline-light ${className}` : 'btn btn-outline-light'
    }
  >
    {children}
  </button>
)

export const BtnOutlineDark = ({
  dataToggle,
  dataTarget,
  onClick,
  className,
  children
}) => (
  <button
    data-toggle={dataToggle}
    data-target={dataTarget}
    onClick={onClick}
    type='button'
    className={
      className ? `btn btn-outline-dark ${className}` : 'btn btn-outline-dark'
    }
  >
    {children}
  </button>
)
