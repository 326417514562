import React from 'react'
import Card, { CardImgTop, CardColumns } from './card'

import commercial_1 from '../static/photos/commercial-1.jpg'
import commercial_2 from '../static/photos/commercial-2.jpg'
import commercial_3 from '../static/photos/commercial-3.jpg'
import commercial_4 from '../static/photos/commercial-4.jpg'
import commercial_5 from '../static/photos/commercial-5.jpg'
import commercial_6 from '../static/photos/commercial-6.jpg'
import commercial_7 from '../static/photos/commercial-7.jpg'
import commercial_8 from '../static/images/image1.jpg'
import commercial_9 from '../static/images/image11.jpg'


const CommercialGallery = () => {
  return (
    <CardColumns>
      {/* COLUMN ONE */}
      <Card>
        <CardImgTop src={commercial_1} />
      </Card>
      <Card>
        <CardImgTop src={commercial_2} />
      </Card>
      <Card>
        <CardImgTop src={commercial_3} />
      </Card>
      {/* END COLUMN ONE */}

      {/* COLUMN TWO */}
      <Card>
        <CardImgTop src={commercial_4} />
      </Card>
      <Card>
        <CardImgTop src={commercial_5} />
      </Card>
      <Card>
        <CardImgTop src={commercial_6} />
      </Card>
      {/* END COLUMN TWO */}

      {/* COLUMN THREE */}
      <Card>
        <CardImgTop src={commercial_7} />
      </Card>
      <Card>
        <CardImgTop src={commercial_8} />
      </Card>
      <Card>
        <CardImgTop src={commercial_9} />
      </Card>
      {/* END COLUMN THREE */}
    </CardColumns>
  )
}

export default CommercialGallery
