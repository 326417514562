import React from 'react'

const Card = ({ style, className, children }) => {
  return (
    <div style={style} className={className ? `card ${className}` : 'card'}>
      {children}
    </div>
  )
}

export default Card

export const CardImgTop = ({ style, className, src, alt }) => {
  return (
    <img
      style={style}
      className={className ? `card-img-top ${className}` : 'card-img-top'}
      src={src}
      alt={alt}
    />
  )
}

export const CardBody = ({ style, className, children }) => {
  return (
    <div
      style={style}
      className={className ? `card-body ${className}` : 'card-body'}
    >
      {children}
    </div>
  )
}

export const CardFooter = ({ style, className, children }) => {
  return (
    <div
      style={style}
      className={className ? `card-footer ${className}` : 'card-footer'}
    >
      {children}
    </div>
  )
}

export const CardTitle = ({ style, className, children }) => {
  return (
    <h5
      style={style}
      className={className ? `card-title ${className}` : 'card-title'}
    >
      {children}
    </h5>
  )
}

export const CardText = ({ style, className, children }) => {
  return (
    <p
      style={style}
      className={className ? `card-text ${className}` : 'card-text'}
    >
      {children}
    </p>
  )
}

export const CardDeck = ({ style, className, children }) => {
  return (
    <div
      style={style}
      className={className ? `card-deck ${className}` : 'card-deck'}
    >
      {children}
    </div>
  )
}

export const CardColumns = ({ style, className, children }) => {
  return (
    <div
      style={style}
      className={className ? `card-columns ${className}` : 'card-columns'}
    >
      {children}
    </div>
  )
}
