import React from 'react'
import NavbarHome from './navbarHome'
import Carousel from './carousel'
import Main from '../../components/main'
import Services from './services'
import Gallery from './gallery'
import Modal from './modal'
import RequestEstimate from './requestEstimate'
import Footer from './footer'

const Home = () => {
  return (
    <>
      <NavbarHome />

      <Carousel />

      <Main>
        <Services />

        <Gallery />
      </Main>

      <Modal>
        <RequestEstimate />
      </Modal>

      <Footer />
    </>
  )
}

export default Home
